import React from 'react';
import { styled } from '@hiyllo/ux/styled';
import * as GetSelfBP from '../blueprints/accounts/get-self';
import * as GetUsersBP from '../blueprints/admin/get-users';
import * as GrantRoleBP from '../blueprints/admin/grant-role';
import * as DeleteUserBP from '../blueprints/admin/delete-user';
import * as RemoveMFABP from '../blueprints/admin/remove-mfa';
import * as CommandeerAccountBP from '../blueprints/admin/commandeer-account';
import * as SetUserPasswordBP from '../blueprints/admin/set-user-password';
import * as MakeFedSuperadminBP from '../blueprints/admin/make-fed-superadmin';
import * as UpgradeAccountToHiylloBP from '../blueprints/accounts/upgrade-account-to-hiyllo';
import { SESSION_TOKEN_KEY, seamlessClient } from '../seamless-client';
import { LoadingSpinner } from '@hiyllo/ux/loading-spinner';
import { useAuthenticateActionWithMFA } from './authenticate-action-with-mfa';
import { MoopsyError } from '@moopsyjs/core';
import { useShowConfirm, useShowDialog } from '@hiyllo/ux/dialogs';
import { hashString } from '../accounts/helpers/hash-password';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-light-svg-icons';
import {
  Table, TableCell, TableRow
} from '@hiyllo/ux/table';
import { AuthenticationMethodEnum, ExtendedUserType } from '../types/extended-user-type';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  height: 'calc(100% - 40px)',
  overflowY: 'auto',
  padding: 20
}));

export const Admin = React.memo(function Admin (props: {self: GetSelfBP.ResponseType}): JSX.Element {
  const getUsersQuery = seamlessClient.useQuery<GetUsersBP.Plug>(GetUsersBP, null);
  const commandeerAccountMutation = seamlessClient.useMutation<CommandeerAccountBP.Plug>(CommandeerAccountBP);
  const grantRoleMutation = seamlessClient.useMutation<GrantRoleBP.Plug>(GrantRoleBP);
  const deleteUserMutation = seamlessClient.useMutation<DeleteUserBP.Plug>(DeleteUserBP, { querySideEffects: [getUsersQuery] });
  const removeMFAMutation = seamlessClient.useMutation<RemoveMFABP.Plug>(RemoveMFABP);
  const setUserPasswordMutation = seamlessClient.useMutation<SetUserPasswordBP.Plug>(SetUserPasswordBP);
  const upgradeAccountToHiylloMutation = seamlessClient.useMutation<UpgradeAccountToHiylloBP.Plug>(UpgradeAccountToHiylloBP);
  const makeFedSuperadminMutation = seamlessClient.useMutation<MakeFedSuperadminBP.Plug>(MakeFedSuperadminBP);
  const { authenticateActionWithMFA } = useAuthenticateActionWithMFA();
  const showDialog = useShowDialog();

  const makeFedSuperadmin = React.useCallback((uuid: string) => {
    void showDialog({
      title: 'Make Federation Superadmin',
      message: `Make ${uuid} a superadmin of their federation`,
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onConfirm: async () => {
        const u2fPack = await authenticateActionWithMFA('make-fed-superadmin');

        await makeFedSuperadminMutation.call({
          uuid,
          u2fPack
        }).then(() => {
          alert('Success');
        });
      }
    });
  }, [authenticateActionWithMFA, makeFedSuperadminMutation, showDialog]);

  const changeUserPassword = React.useCallback((userId: string) => {
    void showDialog({
      title: 'Change User Password',
      message: `Enter new password for user ${userId}`,
      requireValue: true,
      password: true,
      onSubmit: async (value: string) => {
        const u2fPack = await authenticateActionWithMFA(`admin/set-user-password/${userId}`);
        await setUserPasswordMutation.call({
          userId,
          password: await hashString(value),
          u2fPack
        }).then(() => {
          alert('Success');
        });
      }
    });
  }, [authenticateActionWithMFA, setUserPasswordMutation, showDialog]);

  const showConfirm = useShowConfirm();
  const deleteUser = React.useCallback(async (user: ExtendedUserType) => {
    const confirmed = await showConfirm({
      title: `Delete user ${user.hiylloID}`,
      message: `Email: ${user.email?.address ?? 'None'} | UUID: ${user.uuid} | Federation: ${user.federationDetails?.slug ?? 'None'}`
    });

    if (!confirmed) {
      return;
    }

    const u2fPack = await authenticateActionWithMFA('delete-user/' + user.uuid);

    void deleteUserMutation.call({
      uuid: user.uuid,
      u2fPack
    }).catch(err => {
      alert((err as MoopsyError).message);
    });
  }, [authenticateActionWithMFA, deleteUserMutation, showConfirm]);

  const removeMFA = React.useCallback(async (uuid: string, hiylloID: string) => {
    if (!confirm(`Remova MFA for user ${uuid}/${hiylloID}?`)) {
      return;
    }

    const u2fPack = await authenticateActionWithMFA('remove-mfa/' + uuid);

    void removeMFAMutation.call({
      uuid,
      u2fPack
    }).then(() => {
      alert('Success');
    });
  }, [authenticateActionWithMFA, removeMFAMutation]);

  const commandeerAccount = React.useCallback((uuid: string) => {
    void authenticateActionWithMFA(`commandeer-account/${uuid}`).then((u2fPack) => {
      const commandeerPassword = window.prompt('Enter commandeer password');

      if (commandeerPassword == null) {
        return;
      }

      void commandeerAccountMutation.call({
        uuid,
        u2fPack,
        commandeerPassword
      }).then(res => {
        window.localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify(res));
        window.location.reload();
      });
    });
  }, [authenticateActionWithMFA, commandeerAccountMutation]);

  const upgradeToEmployee = React.useCallback((uuid: string) => {
    if (!confirm('Upgrade to employee?')) {
      return;
    }

    const username = prompt('Username?');

    if (username == null) {
      return;
    }

    void upgradeAccountToHiylloMutation.call({
      uuid,
      username
    }).then(() => {
      alert('Success');
    });
  }, [upgradeAccountToHiylloMutation]);

  const giveRole = React.useCallback((uuid: string) => {
    const role = prompt('Role?');

    if (role == null) {
      return;
    }

    void authenticateActionWithMFA(`grant-role/${uuid}/${role}`).then((u2fPack) => {
      void grantRoleMutation.call({
        uuid,
        role,
        u2fPack
      }).then(() => {
        alert('Success');
      }).catch((err: any) => {
        alert((err as MoopsyError).message);
      });
    });
  }, [authenticateActionWithMFA, grantRoleMutation]);

  return (
    <Container>
      {getUsersQuery.data != null
        ? <Table header={['HiylloID', 'Federation', 'UUID', 'Verified Name', 'Email Address', 'Security', 'Actions']}>
          {getUsersQuery.data.users.map((user) => (
            <TableRow key={user.uuid}>
              <TableCell>
                <code>
                  {user.hiylloID}
                </code>
              </TableCell>
              <TableCell>
                {user.federationDetails?.slug ?? '<Unfederated>'}
              </TableCell>
              <TableCell>
                <code>
                  {user.uuid}
                </code>
              </TableCell>
              <TableCell>
                {user.verifiedDetails != null
                  ? <div>
                    {user.verifiedDetails.firstName} {user.verifiedDetails.lastName} <FontAwesomeIcon icon={faBadgeCheck}/>
                  </div>
                  : user.name ?? 'No Name'}
              </TableCell>
              <TableCell>
                {user.email?.address ?? 'No Email'}
              </TableCell>
              <TableCell>
                {user.primaryAuthenticationMethod === AuthenticationMethodEnum.EmailOTP ? 'Email OTP' : 'Password'}{user.hasMFA ? ' w/ MFA' : null}
              </TableCell>
              <TableCell>
                <button onClick={() => commandeerAccount(user.uuid)}>CMNDR</button>
                <button onClick={() => giveRole(user.uuid)}>+Role</button>
                {user.hasMFA
                  ? <button onClick={() => { void removeMFA(user.uuid, user.hiylloID); }}>-MFA</button>
                  : null}
                <button onClick={() => changeUserPassword(user.uuid)}>Set Pswd</button>
                <button onClick={() => makeFedSuperadmin(user.uuid)}>/\ Fed Admin</button>
                <button onClick={() => {
                  void deleteUser(user);
                }}>Delete</button>
              </TableCell>
            </TableRow>
          ))}
        </Table>
        : <LoadingSpinner/>
      }
    </Container>
  );
});
