/* eslint-disable no-useless-call */
import React from 'react';
import { styled } from '@hiyllo/ux/styled';

import * as GetSelfBP from '../blueprints/accounts/get-self';
import * as LogoutBP from '../blueprints/accounts/logout';
import * as DisableU2FBP from '../blueprints/security/disable-u2f';
import * as ChangeSelfPasswordBP from '../blueprints/security/change-self-password';
import * as CreateVerificationSessionBP from '../blueprints/accounts/create-verification-session';
import * as CompleteIdentityVerificationBP from '../blueprints/accounts/complete-identity-verification';
import { SESSION_TOKEN_KEY, seamlessClient } from '../seamless-client';
import { MFARegistrationPanel } from './mfa-settings';
import { Admin } from './admin';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { useAuthenticateActionWithMFA } from './authenticate-action-with-mfa';
import { loadStripe } from '@stripe/stripe-js';

import { Typography } from '@hiyllo/ux/typography';
import { Button } from '@hiyllo/ux/button';
import { LoginUI } from '@hiyllo/ux/login-ui';
import { hashString } from '../accounts/helpers/hash-password';
import { MoopsyError } from '@moopsyjs/core';
import { useAlertDialog, useShowDialog } from '@hiyllo/ux/dialogs';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import {
  faBadgeCheck, faCheck, faShieldCheck
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  color: $theme.foreground,
  height: 'calc(100% - 80px)',
  fontFamily: 'hiyllo',
  paddingTop: 40,
  paddingBottom: 40,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: 50,
  gap: 10
}));

const Inner = styled('div', ({ $theme }) => ({
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  height: '100%'
}));

const LabeledDetail = React.memo(function LabeledDetail ({ label, children }: { label: string, children: React.ReactNode }): JSX.Element {
  return (
    <div>
      <div style={{
        fontFamily: 'hiyllo',
        fontSize: 15
      }}>{label}</div>
      <div style={{
        fontFamily: 'hiyllo',
        fontSize: 20
      }}>
        {children}
      </div>
    </div>
  );
});

const BadgeContainer = styled('div', ({ $theme }) => ({
  background: $theme.background3,
  padding: 7.5,
  borderRadius: 5
}));

export const Dashboard = React.memo(function Dashboard (): JSX.Element {
  const selfQuery = seamlessClient.useQuery<GetSelfBP.Plug>(GetSelfBP, null);
  const disableU2FMutation = seamlessClient.useMutation<DisableU2FBP.Plug>(DisableU2FBP, { querySideEffects: [selfQuery] });
  const createVerificationSessionMutation = seamlessClient.useMutation<CreateVerificationSessionBP.Plug>(CreateVerificationSessionBP);
  const completeIdentityVerificationMutation = seamlessClient.useMutation<CompleteIdentityVerificationBP.Plug>(CompleteIdentityVerificationBP);
  const { authenticateActionWithMFA } = useAuthenticateActionWithMFA();
  const showDialog = useShowDialog();

  const disableU2F = React.useCallback(async () => {
    const u2fPack = await authenticateActionWithMFA('disable-u2f');
    void disableU2FMutation.call({ u2fPack });
  }, [authenticateActionWithMFA, disableU2FMutation]);

  const [currentPassword, setCurrentPassword] = React.useState<string | null>(null);
  const changePasswordMutation = seamlessClient.useMutation<ChangeSelfPasswordBP.Plug>(ChangeSelfPasswordBP);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = React.useState(false);

  const completePasswordChange = React.useCallback(async (newPassword: string) => {
    if (currentPassword == null) return;

    void changePasswordMutation.call({
      oldPassword: await hashString(currentPassword),
      newPassword: await hashString(newPassword),
      logoutOtherSessions: false
    }).catch((err) => {
      setCurrentPassword(null);
      alert((err as MoopsyError).message);
    }).then(() => {
      setPasswordChangeSuccess(true);
    });
  }, [changePasswordMutation, currentPassword]);
  const logoutMutation = seamlessClient.useMutation<LogoutBP.Plug>(LogoutBP);
  const showAlert = useAlertDialog();

  const requestIdentityVerification = React.useCallback(async () => {
    const { sessionId, clientSecret } = await createVerificationSessionMutation.call(null);

    const stripeClient = await loadStripe('pk_live_51LmpU9C8hWRqxVWAVILhlntvsTUDazuJGmolnH5oZNqZ42U24zYXtgARgiaJVQQhxT0HVslNvXYcG0Y6jFpcKzYd00ctSJ0tZy');

    if (stripeClient == null) {
      return;
    }

    const { error } = await stripeClient.verifyIdentity(clientSecret);

    if (error != null) {
      void showAlert({
        title: 'Verification Failed',
        message: 'We were unable to verify your identity. Please try again.'
      });

      return;
    }

    await completeIdentityVerificationMutation.call({ sessionId });

    void selfQuery.refresh({ subtle: true });
  }, [completeIdentityVerificationMutation, createVerificationSessionMutation, selfQuery, showAlert]);

  // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
  if (selfQuery.data != null && selfQuery.data.roles.includes('admin') && window.location.pathname === '/admin') {
    return <Admin self={selfQuery.data}/>;
  }

  if (selfQuery.data == null) {
    return (
      <LoadingPage/>
    );
  }

  if (window.location.pathname === '/change-password') {
    if (passwordChangeSuccess) {
      return (
        <EmptySplash
          icon={faCheck}
          label='Password Changed!'
        />
      );
    }

    if (currentPassword == null) {
      return (
        <LoginUI
          key="obtain-current-password"
          authenticatorType='password'
          logoPath='https://cdn.hiyllo.net/logo/hiyllo/icon-gradient-logo-white.png'
          overrideHeader="Change Password"
          overrideAuthenticatorLabel="Confirm Current Password"
          fixedIdentifier={selfQuery.data.user.email?.address}
          disableIdentifier={true}
          showAuthenticator={true}
          onSubmitAuthenticator={setCurrentPassword}
        />
      );
    }

    return (
      <LoginUI
        key="obtain-new-password"
        authenticatorType='password'
        logoPath='https://cdn.hiyllo.net/logo/hiyllo/icon-gradient-logo-white.png'
        overrideHeader="Change Password"
        overrideAuthenticatorLabel="New Password"
        fixedIdentifier={selfQuery.data.user.email?.address}
        disableIdentifier={true}
        showAuthenticator={true}
        onSubmitAuthenticator={p => {
          void completePasswordChange(p);
        }}
        isLoading={changePasswordMutation.isLoading}
      />
    );
  }

  return (
    <Container>
      <img
        src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient-logo-white.png"
        style={{
          height: 32,
          width: 'auto'
        }}
      />
      <div style={{
        height: 0,
        flexGrow: 1
      }}/>
      <Typography.Header>Your Hiyllo Account</Typography.Header>

      {selfQuery.data.roles.includes('hiyllo-employee')
        ? <BadgeContainer><FontAwesomeIcon icon={faShieldCheck}/> Hiyllo Employee</BadgeContainer>
        : null}

      <LabeledDetail label="Your Email">
        {selfQuery.data?.user.email?.address}
      </LabeledDetail>

      <LabeledDetail label="Your UUID">
        {selfQuery.data?.user.uuid}
      </LabeledDetail>

      {selfQuery.data?.user.name != null
        ? <LabeledDetail label="Your Name">
          {selfQuery.data?.user.name}&nbsp;
          {selfQuery.data?.user.verifiedDetails != null
            ? <FontAwesomeIcon icon={faBadgeCheck} style={{ color: '#4caf50' }}/>
            : null}
        </LabeledDetail>
        : null}

      <div style={{ height: 16 }}/>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10
      }}>
        <Button onClick={() => {
          window.location.href = '/logout';
        }} label="Logout" isLoading={logoutMutation.isLoading}/>
        {selfQuery.data.user.verifiedDetails == null
          ? <Button onClick={() => { void requestIdentityVerification(); }} label="Verify your Identity" isLoading={completeIdentityVerificationMutation.isLoading}/>
          : null}
      </div>

      <div style={{ height: 16 }}/>

      <div>
        {!selfQuery.data?.user.hasMFA
          ? <MFARegistrationPanel/>
          : (
            <div>
                MFA is active. You&apos;ll be asked to use your passkey, biometrics, or security key after entering your password when you sign in.&nbsp;
              <button onClick={() => {
                void disableU2F();
              }}>Disable MFA</button>
            </div>
          )}
      </div>
      <div style={{
        height: 32,
        flexGrow: 1
      }}/>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 32
      }}>
        <a href="https://www.hiyllo.work">
          <img
            src="https://www.hiyllo.work/work-logo.png"
            style={{
              height: 32,
              width: 'auto'

            }}
          />
        </a>
        <a href="https://solo.hiyllo.io">
          <img
            src="https://cdn.hiyllo.net/logo/solo/gradient.png"
            style={{
              height: 32,
              width: 'auto'

            }}
          />
        </a>
      </div>
    </Container>
  );
});
