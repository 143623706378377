import React from 'react';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { AuthExtensionStatus, TransportStatus } from '@moopsyjs/react';

import {
  getSavedTokenPackage, seamlessAuth, seamlessClient
} from '../seamless-client';

export const AuthenticationProvider = React.memo(function AuthenticationProvider (props: React.PropsWithChildren): JSX.Element {
  const isAuthenticated = getSavedTokenPackage() != null;
  const authStatus = seamlessAuth.useAuthStatus();
  const connection = seamlessClient.use.transportStatus();

  if (isAuthenticated && (authStatus === AuthExtensionStatus.loggingIn || connection !== TransportStatus.connected)) {
    return <LoadingPage/>;
  }

  return <>{props.children}</>;
});
