import React from 'react';
import { useParams } from 'react-router-dom';
import * as PerformSSOLoginBlueprint from '../blueprints/internal-auth/login-to-internal';
import { LoadingPage } from '@hiyllo/ux/standard-loader';
import { seamlessClient } from '../seamless-client';
import { MoopsyError } from '@moopsyjs/core/main';
import { useShowAlert } from '@hiyllo/ux/dialogs';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

export const InternalAuth = (): JSX.Element => {
  const performSSOLoginMutation = seamlessClient.useMutation<PerformSSOLoginBlueprint.Plug>(PerformSSOLoginBlueprint);
  const showAlert = useShowAlert();

  const params = useParams<{domain: string}>();
  const [error, setError] = React.useState<MoopsyError | null>(null);

  React.useEffect(() => {
    const domain = params.domain;

    if (domain == null) {
      return;
    }

    if (domain === 'localhost') {
      if (!confirm('You are trying to login to localhost, are you sure you want to proceed?')) {
        return;
      }
    }

    const then = new window.URLSearchParams(window.location.search).get('then');

    void performSSOLoginMutation.call({ domain }).then(res => {
      window.location.href = `http${domain !== 'localhost:3000' ? 's' : ''}://${domain}/iauth?token=${res.token}&then=${then ?? '/'}`;
    }).catch(err => {
      if ((err as MoopsyError).error === 'not-verified') {
        void showAlert({
          title: 'Verify your identity to continue',
          message: "You need to verify your identity before you can continue. We're taking you to your account page, click Verify your Identity to continue."
        }).then(() => {
          window.location.pathname = '/';
        });
      } else {
        setError(err as MoopsyError);
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.domain]);

  if (error != null) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label='Unable to login'
        hint={error.message}
      />
    );
  }

  return (
    <LoadingPage/>
  );
};
