/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
// @ts-ignore[module-not-found]
import { PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types';
import { createJSONSchemaFromRequiredProperties, StringSchemaType } from '../../types/json-schema';

export type ParamsType = { hiylloID: string };
export type ResponseType = { challengeId: string, options: PublicKeyCredentialRequestOptionsJSON };
export const Endpoint = 'login/start-u2f-login-authentication';
export const Method = 'POST';

export interface Plug {
  params: ParamsType
  response: ResponseType
  method: typeof Method
  endpoint: typeof Endpoint
}
export const isPublic = true;
export const paramsSchema = createJSONSchemaFromRequiredProperties({
  hiylloID: StringSchemaType
});