import React from 'react';
import { Input } from '@hiyllo/ux/input';
import { styled } from '@hiyllo/ux/styled';
import { useShowAlert } from '@hiyllo/ux/dialogs';
import { Typography } from '@hiyllo/ux/typography';
import { CircleButton } from '@hiyllo/ux/circle-button';
import { AnimateChangeInHeight } from '@hiyllo/ux/animation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight, faLock, faShield, faSparkles
} from '@fortawesome/pro-light-svg-icons';

import * as RegisterWithFederationBP from '../blueprints/register/register-with-federation';
import {
  seamlessAuth, seamlessClient, SESSION_TOKEN_KEY
} from '../seamless-client';
import { hashString } from '../accounts/helpers/hash-password';
import { AuthExtensionStatus } from '@moopsyjs/react/main';
import { checkCSRF } from '../lib/csrf';
import { Button } from '@hiyllo/ux/button';
import { InlineLink } from '@hiyllo/ux/inline-link';
import { validatePassword } from '../accounts/methods/validate-password';

const Page = styled('div', ({ $theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: $theme.background1,
  color: $theme.foreground,
  fontFamily: 'hiyllo'
}));

const Hint = styled('div', ({ $theme }) => ({
  fontSize: 12,
  color: $theme.foregroundInactive,
  marginTop: 5
}));

export const CreateFederation = React.memo(function CreateFederation (): JSX.Element {
  const from: 'support' | 'work' = (new window.URLSearchParams(window.location.search).get('from') as any) ?? 'work';
  const continuePath = (new window.URLSearchParams(window.location.search).get('then') as string) ?? 'https://www.hiyllo.io';

  checkCSRF(continuePath);

  const authStatus = seamlessAuth.useAuthStatus();
  const authState = seamlessAuth.useAuthState();
  React.useEffect(() => {
    if (authStatus === AuthExtensionStatus.loggedIn && authState != null) {
      if (authState.hiylloID.includes('/')) {
        const slug = authState.hiylloID.split('/')[0];
        window.location.href = continuePath.replace('<tenant>', slug);
      }
      if (authState.hiylloID.endsWith('hiyllo.io')) {
        window.location.href = continuePath.replace('<tenant>', 'www');
      }
    }
  }, [authState, authStatus, continuePath]);

  const registerMutation = seamlessClient.useMutation<RegisterWithFederationBP.Plug>(RegisterWithFederationBP);

  const [stage, setStage] = React.useState<'user' | 'org'>('user');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [slug, setSlug] = React.useState('');
  const [name, setName] = React.useState('');
  const [username, setUsername] = React.useState<string | null>(null);

  const derivedUsername = React.useMemo(() => {
    return email.split('@')[0];
  }, [email]);

  const [slugIsFocused, setSlugIsFocused] = React.useState(false);

  const showAlert = useShowAlert();

  const nextStage = React.useCallback(async () => {
    if (stage === 'user') {
      if (email.length < 5) {
        void showAlert({
          title: 'Invalid email',
          message: 'Please enter a valid email address'
        });
        return;
      }

      const resolvedUsername = username ?? derivedUsername;

      if (resolvedUsername.length < 1) {
        void showAlert({
          title: 'Before we move on...',
          message: 'Can you pick a username?'
        });
        return;
      }

      const passwordError = validatePassword(password);
      if (passwordError != null) {
        void showAlert({
          title: 'Before we move on...',
          message: passwordError
        });
        return;
      }

      setStage('org');
    } else {
      if (slug.length < 3) {
        void showAlert({
          title: 'Before we move on...',
          message: 'Can you make your .hiyllo domain a little longer? It needs to be at least 3 characters'
        });
        return;
      }

      if (name.length < 1) {
        void showAlert({
          title: 'Before we move on...',
          message: 'Can you tell us your organizations name?'
        });
      }

      registerMutation.call({
        email,
        password: await hashString(password),
        username: username ?? derivedUsername,
        name,
        slug
      }).then(async (res) => {
        try {
          // @ts-expect-error --- works on some browsers
          const cred = new window.PasswordCredential({
            id: email,
            password,
            iconURL: 'https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png',
            name: username
          });
          await navigator.credentials.store(cred);
        } catch (e) {
        //
        }

        window.localStorage.setItem(SESSION_TOKEN_KEY, JSON.stringify({ token: res.token }));
        const then = encodeURIComponent(continuePath.replace('<tenant>', slug));

        window.location.href = `https://${slug}.f.hiyllo.cloud/ta/${res.transferToken}?then=${then}`;
      }).catch((err) => {
        void showAlert({
          title: 'Something went wrong',
          message: err.message
        });
      });
    }
  }, [continuePath, derivedUsername, email, name, password, registerMutation, showAlert, slug, stage, username]);

  if (authState != null && !authState.hiylloID.includes('/')) {
    return (
      <Page>
        <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png" style={{
          height: 50,
          width: 50
        }}/>
        <div style={{ height: 20 }}/>
        <Typography.Header>Hey there!</Typography.Header>
        <div style={{ width: 300 }}>
          <Typography.SubHeader>You&apos;re currently signed in to a personal Hiyllo Account. To continue with Hiyllo Business, we need to sign you out so you can create a new Business Hiyllo Account.</Typography.SubHeader>
          <div style={{ height: 10 }}/>
          <Button onClick={() => {
            window.localStorage.clear();
            window.location.reload();
          }} label="Let's do it!"/>
          <div style={{ marginTop: 20 }}>
            Need help? Contact support@hiyllo.io
          </div>
        </div>
      </Page>
    );
  }

  if (stage === 'org') {
    return (
      <Page>
        <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png" style={{
          height: 50,
          width: 50
        }}/>
        <div style={{ height: 20 }}/>
        <Typography.Header>Welcome to Hiyllo for Business</Typography.Header>
        <Typography.SubHeader>Let&apos;s get your organization setup</Typography.SubHeader>
        <div style={{ height: 20 }}/>
        <div style={{ width: 300 }}>
          <Typography.Label>What&apos;s your organizations name?</Typography.Label>
          <Input
            value={name}
            onChangeValue={setName}
            fullWidth
            key="name"
            autoComplete='off'
          />
          <Hint>We&apos;ll use this name when you invite your team members</Hint>
        </div>
        <div style={{ height: 20 }}/>
        <div style={{ width: 300 }}>
          <Typography.Label>Choose a .hiyllo domain</Typography.Label>
          <Input
            placeholder='yourcompany'
            inputStyle={{ textAlign: 'right' }}
            endEnhancer={<span>.hiyllo.{from}</span>}
            value={slug}
            onChangeValue={setSlug}
            fullWidth
            onKeyDown={() => setSlugIsFocused(true)}
            regexp={/^[a-zA-Z0-9]+$/}
            onBlur={() => setSlugIsFocused(false)}
            key="slug"
            onSubmit={nextStage}
          />
          {!slugIsFocused && slug.length > 0 && slug.length < 3
            ? <Hint>Just make it a tad longer</Hint>
            : null}
        </div>
        <div style={{ height: 20 }}/>
        <CircleButton
          icon={faArrowRight}
          size={40}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={nextStage}
          awaitOnClickForLoading
        />
      </Page>
    );
  }

  return (
    <Page>
      <img src="https://cdn.hiyllo.net/logo/hiyllo/icon-gradient.png" style={{
        height: 50,
        width: 50
      }}/>
      <div style={{ height: 20 }}/>
      <Typography.Header>Welcome to Hiyllo for Business</Typography.Header>
      <Typography.SubHeader>It&apos;s great to meet you, let&apos;s get you an account.</Typography.SubHeader>
      <div style={{ height: 20 }}/>
      <div style={{ width: 300 }}>
        <Typography.Label>What&apos;s your work email?</Typography.Label>
        <Input
          value={email}
          onChangeValue={setEmail}
          fullWidth
          placeholder='taylor@hiyllo.com'
          key="email"
          type="email"
          autoComplete='email'
        />
        <Hint><FontAwesomeIcon icon={faShield} fixedWidth/> We never share any of your data with third-parties</Hint>
      </div>
      <div style={{ height: 20 }}/>
      <div style={{ width: 300 }}>
        <Typography.Label>What would you like your username to be?</Typography.Label>
        <Input
          value={username != null ? username : derivedUsername}
          onChangeValue={setUsername}
          fullWidth
          placeholder='taylor'
          key="username"
          onBlur={() => {
            if (username?.length === 0) {
              setUsername(null);
            }
          }}
          autoComplete='off'
        />
        <AnimateChangeInHeight>
          <div style={{ paddingBottom: 5 }}>
            <Hint>
              <FontAwesomeIcon icon={faSparkles} fixedWidth/> Your username can be anything you&apos;d like, usernames are only unique across your organization. {username == null && derivedUsername.length > 0 ? 'We created a username for you, but you can change it if you\'d like' : null}
            </Hint>
          </div>
        </AnimateChangeInHeight>
      </div>
      <div style={{ height: 20 }}/>
      <div style={{ width: 300 }}>
        <Typography.Label>Choose a password</Typography.Label>
        <Input
          placeholder='s3cr#t'
          type='password'
          value={password}
          onChangeValue={setPassword}
          fullWidth
          autoComplete='new-password'
          key="password"
          onSubmit={nextStage}
        />
        <Hint><FontAwesomeIcon icon={faLock} fixedWidth/> Make it a good one you don&apos;t use anywhere else</Hint>
      </div>
      <div style={{ height: 20 }}/>
      <CircleButton
        icon={faArrowRight}
        size={40}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={nextStage}
        isLoading={registerMutation.isLoading}
      />
      <div style={{ height: 20 }}/>
      <div>
        Already have a business account? <a href={'/f/login' + window.location.search} style={{ color: 'white' }}>Login</a>
      </div>
      <div style={{ height: 40 }}/>
      <div style={{ fontSize: 12.5 }}>
        Information you enter will be handled per the Hiyllo Business <InlineLink href='https://www.hiyllo.business/privacy-policy' label='Privacy Policy'/>
      </div>
    </Page>
  );
});
