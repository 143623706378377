import React from 'react';
import { EmptySplash } from '@hiyllo/ux/empty-splash';
import { faLock, faPersonToDoor } from '@fortawesome/pro-light-svg-icons';

import * as LogoutBP from '../blueprints/accounts/logout';
import {
  seamlessAuth, seamlessClient, SESSION_TOKEN_KEY
} from '../seamless-client';
import { AuthExtensionStatus } from '@moopsyjs/react/main';
import { styled } from '@hiyllo/ux/styled';

const Container = styled('div', ({ $theme }) => ({
  background: $theme.background1,
  height: '100%'
}));

export const Logout = React.memo(function Logout (): JSX.Element {
  const logoutMutation = seamlessClient.useMutation<LogoutBP.Plug>(LogoutBP);
  const isLoggedOut = window.localStorage.getItem(SESSION_TOKEN_KEY) == null;
  const authStatus = seamlessAuth.useAuthStatus();

  React.useEffect(() => {
    if (authStatus === AuthExtensionStatus.loggedIn) {
      void logoutMutation.call({
        token: window.localStorage.getItem(SESSION_TOKEN_KEY) ?? '',
        logoutEverywhere: true
      }).then(() => {
        window.localStorage.clear();
        window.location.reload();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  if (isLoggedOut) {
    return (
      <Container>
        <EmptySplash
          icon={faPersonToDoor}
          label="See you later!"
          hint="You have been successfully logged out."
        />
      </Container>
    );
  }

  return (
    <Container>
      <EmptySplash
        icon={faLock}
        label="Logging you out..."
        hint="Please wait while you are securely logged out"
      />
    </Container>
  );
});
